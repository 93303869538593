import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Table, Container } from 'react-bootstrap';
import MDEditor from '@uiw/react-md-editor';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useTableRequest from '../../functions/useTableRequest';
import getQueryString from '../../functions/getQueryString';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import { formatDate } from '../../functions/formatDate';
import { TableFooter } from '../widgets/DataTable';
import ReleaseSlidesModal from './ReleaseSlidesModal';

import '../../stylesheets/releases.css';

const getReleases = (user, updateTokens, { pagination }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ pagination });
  const url = `${process.env.REACT_APP_base_URL}/api/releases/list/web?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const ReleaseRow = ({ release, user, setSelected }) => {
  const { token } = user;

  return (
    <tr>
      <td>
        <a href="#" className="version-link" onClick={() => setSelected(release.id)}>
          {release.version}
        </a>
      </td>
      <td className="slide-image-preview">
        <img src={`${process.env.REACT_APP_base_URL}/api/files/${release.image}?token=${token}`} alt="slide" />
      </td>
      <td>
        <div className="summary-fade-out">
        <MDEditor.Markdown className="mobile-markdown" source={release.summary} />
        </div>
      </td>
      <td>
        {formatDate(new Date(release.publish_date))}
      </td>
    </tr>
  );
}

const Releases = ({ user, updateTokens }) => {
  const [selected, setSelected] = useState(null);

  const [{
    data,
    loading,
    error,
    pagination,
    totals,
  },
  fetchReleases,
  setFilters, //eslint-disable-line
  setPagination] = useTableRequest(getReleases);

  useEffect(() => {
    fetchReleases(user, updateTokens, { pagination });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <Container className="warehouseContenantsControl">
      <h2>
        <Trans i18nKey="releases.title">Releases</Trans>
      </h2>
      {loading && <Loader />}
      {!loading && !!error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <>
          <Table hover className="releases-page">
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <Trans i18nKey="releases.headers.version">Version</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.headers.image">Image</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.headers.summary">Summary</Trans>
                </th>
                <th scope="col" style={{ textWrap: 'nowrap' }}>
                  <Trans i18nKey="releases.headers.publishDate">Publish date</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((r) => (
                <ReleaseRow
                  key={r.id}
                  release={r}
                  user={user}
                  setSelected={setSelected}
                />
              ))}
            </tbody>
          </Table>
          <TableFooter
            pagination={pagination}
            totals={totals}
            setPagination={setPagination}
          />
        </>
      )}
      {selected && (
        <ReleaseSlidesModal
          show={!!selected}
          user={user}
          updateTokens={updateTokens}
          id={selected}
          onClose={() => setSelected(null)}
        />
      )}
    </Container>
  )
}

export default Releases;
