import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import ReleasePreview from '../adminReleases/releasePage/ReleasePreview';
import '../../stylesheets/releases.css';

const getRelease = (user, updateTokens, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${id}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ReleaseSlidesModal = ({ user, updateTokens, id, onClose, show }) => {
  const [data, setData] = useState(null);

  const [{
    loading,
    error,
  }, fetchRelease] = useRequest(
    getRelease, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );

  useEffect(() => {
    fetchRelease(user, updateTokens, id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="slides-modal"
    >
      <Modal.Body>
        {loading && <Loader />}
        {!loading && !!error && <ErrorMessage error={error} />}
        {!loading && !error && data && (
          <ReleasePreview
            user={user}
            slides={[
              {
                title: data.version,
                description: data.summary,
                image: data.image,
              },
              ...data.slides,
            ]}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ReleaseSlidesModal;
