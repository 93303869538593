import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Table,
  Button,
  Row,
  Card,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import Loader from '../../loader';
import ErrorMessage from '../../errorMessage';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';
import Confirm from '../../widgets/Confirm';
import SlideModal from './SlideModal';
import ReleasePreview from './ReleasePreview';
import '../../../stylesheets/adminReleases.css';

const getSlides = (user, updateTokens, releaseId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const setSlideOrder = (user, updateTokens, { releaseId, order }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}/slide/ordering`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ order }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const deleteSlide = (user, updateTokens, { releaseId, slideId }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}/slide/${slideId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
} 

const SlideRow = ({ user, slide, onEdit, upRow, downRow, onRemove }) => {
  const { token } = user;
  const { t } = useTranslation();

  return (
    <tr>
      <td className="slide-image-preview">
        <img src={`${process.env.REACT_APP_base_URL}/api/files/${slide.image}?token=${token}`} alt="slide" />
      </td>
      <td>{slide.title}</td>
      <td>{slide.description}</td>
      <td className="text-center action-buttons">
        <Button
          className="tiny-button"
          size="sm"
          variant="light"
          title={t('releases.editSlide', 'Edit slide')}
          onClick={onEdit}
        >
          <i className="vtmn-icon_edit"></i>
        </Button>
        <Button
          className="tiny-button"
          size="sm"
          variant="light"
          title={t('releases.removeSlide', 'Remove slide')}
          onClick={onRemove}
        >
          <i className="vtmn-icon_delete"></i>
        </Button>
        <Button
          className="tiny-button"
          size="sm"
          variant="light"
          title={t('releases.toStart', 'To the carousel start')}
          onClick={upRow}
        >
          <i className="vtmn-icon_arrow1_up"></i>
        </Button>
        <Button
          className="tiny-button"
          size="sm"
          variant="light"
          title={t('releases.toEnd', 'To the carousel end')}
          onClick={downRow}
        >
          <i className="vtmn-icon_arrow1_down"></i>
        </Button>
      </td>
    </tr>
  );
}

const AdminReleasePage = ({ user, updateTokens }) => {
  const { releaseId } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [release, setRelease] = useState({});
  const [editableSlide, setEditableSlide] = useState({});
  const [removedSlide, setRemovedSlide] = useState();

  const [{
    loading,
    error,
  }, fetchSlides] = useRequest(
    getSlides, {
      onSuccess: (release) => {
        setData(release.slides);
        setRelease(_.omit(release, 'slides'));
      },
      onError: () => setData(null),
    }
  );

  const [{
    error: changeError,
  }, changeOrder] = useRequest(setSlideOrder);

  const [{
    error: deleteError,
  }, removeSlide] = useRequest(deleteSlide);

  useEffect(() => {
    fetchSlides(user, updateTokens, releaseId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseId]);

  const afterSaveSlide = () => {
    fetchSlides(user, updateTokens, releaseId);
    setEditableSlide({});
  }

  const onCloseModal = () => {
    setShowModal(false);
    setEditableSlide({});
  }

  const changeSlideIndex = async (index, newIndex) => {
    if (newIndex < 0 || newIndex === data.length) {
      return;
    }

    if (newIndex < index) {
      const upOrder = [
        ...data.slice(0, newIndex),
        data[index],
        data[newIndex],
        ...data.slice(index + 1),
      ];
      await changeOrder(user, updateTokens, {
        releaseId,
        order: upOrder.map((s, i) => ({ id: s.id, order: i + 1 })),
      });
      setData(upOrder);
    } else {
      const downOrder = [
        ...data.slice(0, index),
        data[newIndex],
        data[index],
        ...data.slice(newIndex + 1),
      ];
      await setSlideOrder(user, updateTokens, {
        releaseId,
        order: downOrder.map((s, i) => ({ id: s.id, order: i + 1 })),
      });
      setData(downOrder);
    }
  }

  const doRemoveSlide = async () => {
    await removeSlide(user, updateTokens, {
      releaseId,
      slideId: removedSlide,
    });
    const removedIndex = data.findIndex((s) => s.id === removedSlide);
    if (removedIndex === -1) {
      return;
    }
    setData([
      ...data.slice(0, removedIndex),
      ...data.slice(removedIndex + 1),
    ]);
    setRemovedSlide();
  }

  const hasError = !!error || !!changeError || !!deleteError

  return (
    <Container className="adminGroups realise-page">
      <Row>
        <Card body className="admin-top-card slide-toolbar">
          {!!releaseId && !!release.version && (
            <div className="release-info">
              Version: {release.version} ({release.type})<br/>
            </div>
          )}
          <Button className="icon-button" onClick={() => setShowModal(true)}>
            <i className="vtmn-icon_edit_plus"></i>
            <Trans i18nKey="releases.addSlide">Add slide</Trans>
          </Button>
        </Card>
      </Row>
      {loading && <Loader />}
      {!loading && hasError && <ErrorMessage error={error || changeError || deleteError} />}
      {!loading && !error && data && (
        <div className="slides-wrapper">
          {!!data.length && (
            <ReleasePreview
              user={user}
              slides={[
                {
                  title: release.version,
                  description: release.summary
                },
                ...data,
              ]}
            />
          )}
          <Table hover>
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <Trans i18nKey="releases.slideHeaders.image">Image</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.slideHeaders.title">Title</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.slideHeaders.description">Description</Trans>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((s, i) => (
                <SlideRow
                  key={s.id}
                  slide={s}
                  user={user}
                  onEdit={() => setEditableSlide(s)}
                  upRow={() => changeSlideIndex(i, i - 1)}
                  downRow={() => changeSlideIndex(i, i + 1)}
                  onRemove={() => setRemovedSlide(s.id)}
                />
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {(!!showModal || !!editableSlide.id) && (
        <SlideModal
          show={showModal || !!editableSlide.id}
          onClose={onCloseModal}
          user={user}
          updateTokens={updateTokens}
          afterSave={afterSaveSlide}
          slide={editableSlide}
        />
      )}
      <Confirm
        title={t('releases.removeSlideConfirm.warning', 'Warning')}
        body={t('releases.removeSlideConfirm.text', 'Are you sure you want to remove selected slide?')}
        show={!!removedSlide}
        onHide={() => setRemovedSlide()}
        loading={false}
        onConfirm={doRemoveSlide}
      />
    </Container>
  );
}

export default AdminReleasePage;
