import React from 'react';
import { Carousel, Card } from 'react-bootstrap';
import MDEditor from '@uiw/react-md-editor';
import '../../../stylesheets/adminReleases.css';

const ReleasePreviewItem = ({ user, title, description, image }) => {
  const { token } = user;

  return (
    <div className="slide-preview-item">
      <div className="slide-image" style={{backgroundImage: `url(${process.env.REACT_APP_base_URL}/api/files/${image}?token=${token})`}}>
      </div>
      <div className="slide-content">
        <div className="slide-title">
          {title}
        </div>
        <div className="slide-description">
          <MDEditor.Markdown className="mobile-markdown" source={description} />
        </div>
      </div>
    </div>
  )
};

const ReleasePreview = ({ user, slides }) => {
  return (
    <Card className="slide-carousel">
      <Card.Body>
        <Carousel interval={null}>
          {!!slides.length && slides.map((slide) => (
            <Carousel.Item key={slide.id}>
              <ReleasePreviewItem
                user={user}
                title={slide.title}
                image={slide.image}
                description={slide.description}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Card.Body>
    </Card>
  )
};

export default ReleasePreview;
