import React, { useState, useEffect } from 'react';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import ReleaseSlidesModal from './ReleaseSlidesModal';

const getLastRelease = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/new/web`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ReleaseNotification = ({ user, updateTokens }) => {
  const [releaseId, setReleaseId] = useState(null);

  const [{
    loading,
    error,
  }, fetchRelease] = useRequest(
    getLastRelease, {
      onSuccess: ({ id }) => setReleaseId(id),
      onError: () => setReleaseId(null),
    }
  );

  useEffect(() => {
    fetchRelease(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && !error && !!releaseId && (
        <ReleaseSlidesModal
          show={!!releaseId}
          user={user}
          updateTokens={updateTokens}
          id={releaseId}
          onClose={() => setReleaseId(null)}
        />
      )}
    </>
  )
}

export default ReleaseNotification;
